// import Home from './components/Home'
// import Games from "@/components/casino/Games";

const Home = () => import(/* webpackChunkName: "match" */ './components/Home')


const Offers = () => import(/* webpackChunkName: "match" */ './components/Offers')
const Login = () => import(/* webpackChunkName: "account" */ './components/Login')
const Join = () => import(/* webpackChunkName: "account" */ './components/Join')
const Reset = () => import(/* webpackChunkName: "account" */ './components/Reset')
const Deposit = () => import(/* webpackChunkName: "account" */ './components/Deposit')
const DepositHowTo = () => import(/* webpackChunkName: "account" */ './components/DepositHowTo')
const Setting = () => import(/* webpackChunkName: "account" */ './components/Setting')
const Terms = () => import(/* webpackChunkName: "help" */ './components/Terms')
const Faq = () => import(/* webpackChunkName: "help" */ './components/Faq')
const Invite = () => import(/* webpackChunkName: "match" */ "@/components/Invite");
const Help = () => import(/* webpackChunkName: "help" */ "./components/Help");
const Responsible = () => import(/* webpackChunkName: "help" */ "./components/Responsible");
const Profile = () => import(/* webpackChunkName: "help" */ "./components/Profile");
const Gift = () => import(/* webpackChunkName: "help" */ "./components/Gift");

//casino menus
const Jetsafi = () => import(/* webpackChunkName: "casino" */ "./components/casino/Jetsafi");
const Casino = () => import(/* webpackChunkName: "casino" */ "./components/casino/Casino");
const CasinoGames = () => import(/* webpackChunkName: "casino" */ "./components/casino/CasinoGames");
const Cointoss = () => import(/* webpackChunkName: "casino" */ "./components/casino/Cointoss");
const Spin = () => import(/* webpackChunkName: "casino" */ "./components/casino/Spin");

export const routes = [
    {
        path: '/jetsafi',
        name: 'jetsafi',
        component: Jetsafi,
        meta: {
            title: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'description',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:description',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:title',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:title',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
            ]
        }
    },
    {
        path: '/',
        name: 'casino',
        component: Casino,
        meta: {
            title: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts',
            metaTags: [
                {
                    name: 'title',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'description',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:description',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:title',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:title',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: "/home",
        name: "home",
        component: Home,
    },
    {
        path: "/cointoss",
        name: "Cointoss",
        component: Cointoss,
    },
    {
        path: "/spin",
        name: "Spin",
        component: Spin,
    },
    {
        path: "/casino/casino-games",
        name: "casinogames",
        component: CasinoGames,
    },
    {
        path: '/invite/:code',
        name: 'invite',
        component: Invite,
    },
    {
        path: '/join',
        name: 'login',
        component: Join,
        meta: {
            title: 'Register',
            metaTags: [
                {
                    name: 'title',
                    content: 'Register'
                },
                {
                    name: 'description',
                    content: 'Register with us today and get Ksh 2000 Welcome Gift.'
                },
                {
                    name: 'keywords',
                    content: 'Register today, Register, Registration'
                }
            ]
        },
    },
    {
        path: '/login',
        name: 'login1',
        component: Login,
        meta: {
            title: 'Login',
            metaTags: [
                {
                    name: 'title',
                    content: 'Login'
                },
                {
                    name: 'description',
                    content: 'Enter your phone number and password below to login to your account'
                },
                {
                    name: 'keywords',
                    content: 'Log in, Play now, login, Registration'
                }
            ]
        },
    },

    {
        path: '/deposit',
        name: 'deposit',
        component: Deposit,
        meta: {
            title: 'Depost To Your Account',
            metaTags: [
                {
                    name: 'description',
                    content: 'Deposit the amount you wish to bet for and start online sports betting with the betst betting company in Africa.'
                },
                {
                    name: 'keyword',
                    content: 'Deposit, Betting, place a bet, how to bet, mobile betting, Withdrawal methods..'
                },
                {
                    property: 'og:description',
                    content: 'Deposit the amount you wish to bet for and start online sports betting with the betst betting company in Africa.'
                },
                {
                    property: 'twitter:description',
                    content: 'Deposit the amount you wish to bet for and start online sports betting with the betst betting company in Africa.'
                },
                {
                    property: 'twitter:title',
                    content: 'Depost To Your Account'
                },
                {
                    property: 'og:title',
                    content: 'Depost To Your Account'
                },
                {
                    property: 'og:type',
                    content: 'Website'
                }
            ]
        }
    },
    {
        path: '/setting',
        name: 'setting',
        component: Setting,
        meta: {
            title: 'My Account | Best online sport betting |Freebet',
            metaTags: [
                {
                    name: 'description',
                    content: 'My account - best online sport betting in africa where you can win a lot of prizes from our live bets,multi bets, daily jackpot and many more.'
                },
                {
                    name: 'keywords',
                    content: 'Freebet, best online sports betting, online games,live bets, bets , odds, sports betting'
                }
            ]
        }
    },
    {
        path: '/my-account',
        name: 'my-account',
        component: Setting,
        meta: {
            title: 'My Account | Best online sport betting |Freebet',
            metaTags: [
                {
                    name: 'description',
                    content: 'My account - best online sport betting in africa where you can win a lot of prizes from our live bets,multi bets, daily jackpot and many more.'
                },
                {
                    name: 'keywords',
                    content: 'Freebet, best online sports betting, online games,live bets, bets , odds, sports betting'
                }
            ]
        }
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms,
        meta: {
            title: 'Terms & Conditions | Responsible Gaming |Sports betting: rules',
            metaTags: [
                {
                    name: 'description',
                    content: 'These are the betting terms and conditions (Sports betting: rules)on how to place your bet while you follow favorite sports in the world\'s top League.'
                },
                {
                    name: 'keywords',
                    content: 'Terms & conditions, Betting, winner, 1st half, Both team to score, half time , full time, sport betting,live sports betting,online betting,bet and win,online football,bet online,soccer bets,champions league,barclays premier league,football betting site'
                }
            ]
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        meta: {
            title: 'FAQs | Bet on sports online and win big| bet and win',
            metaTags: [
                {
                    name: 'description',
                    content: 'Here are detailed answers to the most frequently asked question about  sports betting online .'
                },
                {
                    name: 'keywords',
                    content: 'FAQs, Betting online, online sports betting FAQs, account,paybill number, Mpesa,bet slip, place bet.'
                }
            ]
        }
    },
    {
        path: '/gifts',
        name: 'gifts',
        component: Gift,
        meta: {
            title: 'Gift',
            metaTags: [
                {
                    name: 'title',
                    content: 'Gift'
                },
                {
                    name: 'description',
                    content: 'Gift – Enjoy our Ksh 2000 Welcome Gift when you register with us '
                },
                {
                    name: 'keywords',
                    content: 'Welcome Bonus, Welcome Gift, Bonus, Gifts, Freebet'
                }
            ]
        },
    },
    {
        path: '/deposit-how-to',
        name: 'deposit-how-to',
        component: DepositHowTo,
        meta: {
            title: 'Deposit | Bet on sports online and win big| bet and win',
            metaTags: [
                {
                    name: 'description',
                    content: 'How to Deposit'
                },
                {
                    name: 'keywords',
                    content: 'Gifts, Bonus, FAQs, Betting online, online sports betting FAQs, account,paybill number, Mpesa,bet slip, place bet.'
                }
            ]
        }
    },
    {
        path: '/howto',
        name: 'help',
        component: Help,
        meta: {
            title: 'Help | How To Place a Bet | Online Sports Betting ',
            metaTags: [
                {
                    name: 'description',
                    content: 'Not sure how to start your online sports betting? Here is how to start your online betting game, sign up to join the player community started .'
                },
                {
                    name: 'keywords',
                    content: 'football online , betting , single bet, odds , bet id, sport betting,scores, Get in the game'
                }
            ]
        }
    },
    {
        path: '/responsible-gaming',
        name: 'responsible',
        component: Responsible
    },
    {
        path: '/reset/:msisdn/:code',
        name: 'reset',
        component: Reset,
    },
    {
        path: '/offers',
        name: 'offers',
        component: Offers,
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
    },
    {
        path: '*',
        name: '404',
        component: Casino,
        meta: {
            title: ' Your Best sports betting site| Fast play Fast win',
            metaTags: [
                {
                    name: 'title',
                    content: ' Your Best sports betting site| Fast play Fast win'
                },
                {
                    name: 'description',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    name: 'keywords',
                    content: 'online betting , soccer,basketball, Bet, football bet online,place football bets online, place your bet,pre-game, live bet, how to build a bet.'
                },
                {
                    property: 'og:description',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:description',
                    content: 'Get the Highest ODDS in Kenya, Fastest Live Betting, Easiest Jackpots & Instant Payouts'
                },
                {
                    property: 'twitter:title',
                    content: ' Your Best sports betting site| Fast play Fast win'
                },
                {
                    property: 'og:title',
                    content: ' Your Best sports betting site| Fast play Fast win'
                },
            ]
        }
    },
];